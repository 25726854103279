import { IDatasource, IGetRowsParams } from '@ag-grid-community/core';
import { KeyPerformanceIndicators } from '@sds/api';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { KpiComponent } from './kpi.component';
import { KpiService } from './kpi.service';

export class KpiDatasource implements IDatasource {
  constructor(private readonly service: KpiService) {}

  getRows(params: IGetRowsParams) {
    const kpiComponent: KpiComponent = params?.context?.componentParent;
    if (!kpiComponent.gridApi) {
      return;
    }
    kpiComponent.gridApi?.setGridOption('loading', true);
    const blockSize = params.endRow - params.startRow;
    const page = params.endRow / blockSize;
    const getKpis: Observable<KeyPerformanceIndicators[]> = this.service.getKpis(blockSize, page);

    getKpis.pipe(tap(() => kpiComponent.gridApi?.setGridOption('loading', false))).subscribe({
      next: kpis => {
        if (!kpis.length && params.startRow === 0) {
          return kpiComponent.gridApi?.showNoRowsOverlay();
        }

        let lastRow = -1;
        if (kpis.length < blockSize) {
          lastRow = params.startRow + kpis.length;
        }
        kpiComponent.gridApi?.hideOverlay();
        params.successCallback(kpis, lastRow);
        if (blockSize === kpiComponent.csvExportPageSize) {
          kpiComponent.gridApi?.exportDataAsCsv({
            columnSeparator: ';',
            suppressQuotes: true,
            fileName: 'kpi_export'
          });
        }
        kpiComponent.gridApi?.sizeColumnsToFit();
      },
      error: () => {
        params.successCallback([], 0);
        kpiComponent.gridApi?.showNoRowsOverlay();
        params.failCallback();
      }
    });
  }
}
